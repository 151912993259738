import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Modal from "react-modal";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
// import TagManager from "react-gtm-module";
import { ReactComponent as Cancel } from "./components/assets/images/cancel.svg";
import { ReactComponent as Sclose } from "./components/assets/images/sclose.svg";
import { ReactComponent as Sopen } from "./components/assets/images/sopen.svg";
import Button from "./components/common/button/Button";

const declinedCookies = {
  ad_storage: "denied",
  ad_user_data: "denied",
  ad_personalization: "denied",
  analytics_storage: "denied",
  functionality_storage: "denied",
  personalization_storage: "denied",
  security_storage: "denied",
};

export default function CookiesSetup() {
  const [cookieSettings, setCookieSettings] = useState(false);

  const location = useLocation();

  const [descriptionExpandedMap, setDescriptionExpandedMap] = useState({
    ad_storage: false,
    ad_user_data: false,
    ad_personalization: false,
    analytics_storage: false,
    functionality_storage: false,
    personalization_storage: false,
    security_storage: false,
  });

  const handleSetDescriptionExpandedMap = (field) => {
    setDescriptionExpandedMap((prevState) => {
      return {
        ...prevState,
        [field]: !prevState[field],
      };
    });
  };

  const [userPreference, setUserPreference] = useState({
    ad_storage: "granted",
    ad_user_data: "granted",
    ad_personalization: "granted",
    analytics_storage: "granted",
    functionality_storage: "granted",
    personalization_storage: "granted",
    security_storage: "granted",
  });

  const [loadCookies, setLoadCookies] = useState(false);

  // Cookie Accept
  const handleAccept = () => {
    document.cookie = `CookiePreference=${JSON.stringify(userPreference)}`;
    setLoadCookies(true);
  };

  // Cookie Preference
  const handleDecline = () => {
    document.cookie = `CookieConsent=true`;
    setCookieSettings(true);
  };

  const handleCloseCookie = () => {
    document.cookie = `CookieConsent=true`;
    document.cookie = `CookiePreference=${JSON.stringify(userPreference)}`;
    setCookieSettings(!cookieSettings);
    setLoadCookies(true);
  };

  const handleSaveCookies = () => {
    document.cookie = `CookieConsent=true`;
    document.cookie = `CookiePreference=${JSON.stringify(userPreference)}`;
    setCookieSettings(!cookieSettings);
    setLoadCookies(true);
  };

  const handleRejectCookies = () => {
    setUserPreference((prevState) => {
      return {
        ...prevState,
        performanceCookie: false,
        analyticCookies: false,
        marketingCookies: false,
      };
    });
    document.cookie = `CookieConsent=false`;
    document.cookie = `CookiePreference=${JSON.stringify(declinedCookies)})}`;
    setCookieSettings(!cookieSettings);
    setLoadCookies(true);
  };

  const handleToggleCookies = (field: string) => {
    setUserPreference((prevState) => {
      return {
        ...prevState,
        [field]: prevState[field] === "granted" ? "denied" : "granted",
      };
    });
  };

  const getCookie = (cname: string) => {
    const cookies = {};
    document.cookie.split(";").forEach((el) => {
      const [key, value] = el.split("=");
      cookies[key.trim()] = value;
    });
    return cookies[cname];
  };

  useEffect(() => {
    const getConsent = getCookie("CookieConsent");
    const getPreference = getCookie("CookiePreference");
    const cookieConsent = getConsent === "true";
    const cookiePreference =
      getPreference !== undefined && JSON.parse(getPreference);

    if (!cookieConsent) return;

    if (cookieConsent && cookiePreference.analytics_storage) {
      const { REACT_APP_HOTJAR_ID, REACT_APP_HOTJAR_SV } = process.env || "";
      const hjid: string = REACT_APP_HOTJAR_ID || "";
      const hjsv: string = REACT_APP_HOTJAR_SV || "";
      hotjar.initialize(parseInt(hjid, 10), parseInt(hjsv, 10));
      (window as any).gtag("consent", "update", cookiePreference);

      // const tagManagerArgs = {
      //   gtmId: `${process.env.REACT_APP_GTM_ID}`,
      // };

      // TagManager.initialize(tagManagerArgs);

      ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    // TODO - if there is any performance app should be added here
    // if (cookieConsent && cookiePreference.performanceCookie) {
    //   console.log("load performance");
    // }

    // TODO - if there is any marketing app should be added here
    // if (cookieConsent && cookiePreference.marketingCookies) {
    //   console.log("load marketing");
    // }

    setLoadCookies(false);
  }, [userPreference, loadCookies, location]);

  return (
    <div>
      <CookieConsent
        location="bottom"
        enableDeclineButton
        buttonText="I accept"
        style={{
          background: "#0C152C",
          fontFamily: "Onest-Medium",
          fontSize: "12px",
          color: "#8999C1",
          display: "flex",
          alignItems: "center",
        }}
        declineButtonText="Cookie settings"
        declineButtonClasses="bg-white10 rounded text-white100 py-2 px-4 mx-5 cookiesSetting"
        buttonClasses="btn text-white100 mr-10 px-10 py-2"
        disableButtonStyles
        onDecline={handleDecline}
        onAccept={handleAccept}
        expires={90}
        contentClasses="text-white70"
      >
        We use cookies to offer you a better browsing experience, personalize
        content and ads, provide social media features, and analyze our traffic.
        Read about how we use cookies and how you can control them by clicking
        Cookie Settings. You consent to our cookies if you continue using this
        website.
      </CookieConsent>
      <Modal
        isOpen={cookieSettings}
        className="bg-white10 py-2 smallModal top-[50%] -translate-y-[50%] relative ml-[50%] -translate-x-[50%] z-50 text-sm min-w-[50%] rounded-xl w-full md:w-auto mx-4 max-h-full overflow-auto"
        overlayClassName="cookiesSettingModalOverlay"
        onRequestClose={() => setCookieSettings(!cookieSettings)}
      >
        <div className="flex text-white70 justify-between py-3 border-b border-white15">
          <div className="pl-5 text-lg text-white100"> Cookie setting</div>
          <div>
            <Cancel
              className="mr-5 cursor-pointer"
              onClick={handleCloseCookie}
            />
          </div>
        </div>
        <div className="py-4 border-b border-white15">
          {/* <div className="flex text-white70 justify-between px-5">
            <div>
              {" "}
              Essential website cookies{" "}
              {!esentialDesc && (
                <Sclose
                  onClick={() => setEsentialDesc(!esentialDesc)}
                  className="inline ml-2 cursor-pointer"
                />
              )}
              {esentialDesc && (
                <Sopen
                  onClick={() => setEsentialDesc(!esentialDesc)}
                  className="inline ml-2 cursor-pointer"
                />
              )}
            </div>
            <div> Always on</div>
          </div> */}
          {/* {esentialDesc && (
            <div
              className="text-white70 px-5 pt-2 text-xs"
              style={{ fontFamily: "Onest-Medium" }}
            >
              Necessary cookies help make a website usable by enabling basic
              functions like page navigation and access to secure areas of the
              website. The website cannot function properly without these
              cookies.
            </div>
          )} */}
        </div>
        <div className="py-3 border-b border-white15">
          <div className="flex text-white70 justify-between px-5">
            <div>
              Storage cookies{" "}
              {descriptionExpandedMap.ad_storage ? (
                <Sopen
                  onClick={() => handleSetDescriptionExpandedMap("ad_storage")}
                  className="inline ml-2 cursor-pointer"
                />
              ) : (
                <Sclose
                  onClick={() => handleSetDescriptionExpandedMap("ad_storage")}
                  className="inline ml-2 cursor-pointer"
                />
              )}
            </div>
            <input
              id="privacy"
              type="checkbox"
              checked={userPreference.ad_storage === "granted"}
              onChange={() => {
                handleToggleCookies("ad_storage");
              }}
              className="w-6 h-6 text-blue100  rounded-full 
              focus:ring-yellow  cursor-pointer
              focus:ring-2  ring-offset-yellow accent-yellow
              "
            />
          </div>
          {descriptionExpandedMap.ad_storage && (
            <div
              className="text-white70 px-5 pt-2 text-xs"
              style={{ fontFamily: "Onest-Medium" }}
            >
              Enables storage, such as cookies (web) or device identifiers
              (apps), related to advertising.
            </div>
          )}
        </div>

        <div className="py-3 border-b border-white15">
          <div className="flex text-white70 justify-between px-5">
            <div>
              User data cookies{" "}
              {descriptionExpandedMap.ad_user_data ? (
                <Sopen
                  onClick={() =>
                    handleSetDescriptionExpandedMap("ad_user_data")
                  }
                  className="inline ml-2 cursor-pointer"
                />
              ) : (
                <Sclose
                  onClick={() =>
                    handleSetDescriptionExpandedMap("ad_user_data")
                  }
                  className="inline ml-2 cursor-pointer"
                />
              )}
            </div>
            <input
              id="privacy"
              type="checkbox"
              checked={userPreference.ad_user_data === "granted"}
              onChange={() => {
                handleToggleCookies("ad_user_data");
              }}
              className="w-6 h-6 text-blue100  rounded-full 
              focus:ring-yellow  cursor-pointer
              focus:ring-2  ring-offset-yellow accent-yellow
              "
            />
          </div>
          {descriptionExpandedMap.ad_user_data && (
            <div
              className="text-white70 px-5 pt-2 text-xs"
              style={{ fontFamily: "Onest-Medium" }}
            >
              Sets consent for sending user data to Google for online
              advertising purposes.
            </div>
          )}
        </div>

        <div className="py-3 border-b border-white15">
          <div className="flex text-white70 justify-between px-5">
            <div>
              Personalization cookies{" "}
              {descriptionExpandedMap.ad_personalization ? (
                <Sopen
                  onClick={() =>
                    handleSetDescriptionExpandedMap("ad_personalization")
                  }
                  className="inline ml-2 cursor-pointer"
                />
              ) : (
                <Sclose
                  onClick={() =>
                    handleSetDescriptionExpandedMap("ad_personalization")
                  }
                  className="inline ml-2 cursor-pointer"
                />
              )}
            </div>
            <input
              id="privacy"
              type="checkbox"
              checked={userPreference.ad_personalization === "granted"}
              onChange={() => {
                handleToggleCookies("ad_personalization");
              }}
              className="w-6 h-6 text-blue100  rounded-full 
              focus:ring-yellow  cursor-pointer
              focus:ring-2  ring-offset-yellow accent-yellow
              "
            />
          </div>
          {descriptionExpandedMap.ad_personalization && (
            <div
              className="text-white70 px-5 pt-2 text-xs"
              style={{ fontFamily: "Onest-Medium" }}
            >
              Sets consent for personalized advertising.
            </div>
          )}
        </div>

        <div className="py-3 border-b border-white15">
          <div className="flex text-white70 justify-between px-5">
            <div>
              Analytics storage cookies{" "}
              {descriptionExpandedMap.analytics_storage ? (
                <Sopen
                  onClick={() =>
                    handleSetDescriptionExpandedMap("analytics_storage")
                  }
                  className="inline ml-2 cursor-pointer"
                />
              ) : (
                <Sclose
                  onClick={() =>
                    handleSetDescriptionExpandedMap("analytics_storage")
                  }
                  className="inline ml-2 cursor-pointer"
                />
              )}
            </div>
            <input
              id="privacy"
              type="checkbox"
              checked={userPreference.analytics_storage === "granted"}
              onChange={() => {
                handleToggleCookies("analytics_storage");
              }}
              className="w-6 h-6 text-blue100  rounded-full 
              focus:ring-yellow  cursor-pointer
              focus:ring-2  ring-offset-yellow accent-yellow
              "
            />
          </div>
          {descriptionExpandedMap.analytics_storage && (
            <div
              className="text-white70 px-5 pt-2 text-xs"
              style={{ fontFamily: "Onest-Medium" }}
            >
              Enables storage, such as cookies (web) or device identifiers
              (apps), related to analytics, for example, visit duration.
            </div>
          )}
        </div>

        <div className="py-3 border-b border-white15">
          <div className="flex text-white70 justify-between px-5">
            <div>
              Functionality Storage cookies{" "}
              {descriptionExpandedMap.functionality_storage ? (
                <Sopen
                  onClick={() =>
                    handleSetDescriptionExpandedMap("functionality_storage")
                  }
                  className="inline ml-2 cursor-pointer"
                />
              ) : (
                <Sclose
                  onClick={() =>
                    handleSetDescriptionExpandedMap("functionality_storage")
                  }
                  className="inline ml-2 cursor-pointer"
                />
              )}
            </div>
            <input
              id="privacy"
              type="checkbox"
              checked={userPreference.functionality_storage === "granted"}
              onChange={() => {
                handleToggleCookies("functionality_storage");
              }}
              className="w-6 h-6 text-blue100  rounded-full 
              focus:ring-yellow  cursor-pointer
              focus:ring-2  ring-offset-yellow accent-yellow
              "
            />
          </div>
          {descriptionExpandedMap.functionality_storage && (
            <div
              className="text-white70 px-5 pt-2 text-xs"
              style={{ fontFamily: "Onest-Medium" }}
            >
              Enables storage that supports the functionality of the website or
              app, for example, language settings.
            </div>
          )}
        </div>

        <div className="py-3 border-b border-white15">
          <div className="flex text-white70 justify-between px-5">
            <div>
              Personalization Storage cookies{" "}
              {descriptionExpandedMap.personalization_storage ? (
                <Sopen
                  onClick={() =>
                    handleSetDescriptionExpandedMap("personalization_storage")
                  }
                  className="inline ml-2 cursor-pointer"
                />
              ) : (
                <Sclose
                  onClick={() =>
                    handleSetDescriptionExpandedMap("personalization_storage")
                  }
                  className="inline ml-2 cursor-pointer"
                />
              )}
            </div>
            <input
              id="privacy"
              type="checkbox"
              checked={userPreference.personalization_storage === "granted"}
              onChange={() => {
                handleToggleCookies("personalization_storage");
              }}
              className="w-6 h-6 text-blue100  rounded-full 
              focus:ring-yellow  cursor-pointer
              focus:ring-2  ring-offset-yellow accent-yellow
              "
            />
          </div>
          {descriptionExpandedMap.personalization_storage && (
            <div
              className="text-white70 px-5 pt-2 text-xs"
              style={{ fontFamily: "Onest-Medium" }}
            >
              Enables storage related to personalization, for example, video
              recommendations.
            </div>
          )}
        </div>

        <div className="py-3 border-b border-white15">
          <div className="flex text-white70 justify-between px-5">
            <div>
              Security Storage cookies{" "}
              {descriptionExpandedMap.security_storage ? (
                <Sopen
                  onClick={() =>
                    handleSetDescriptionExpandedMap("security_storage")
                  }
                  className="inline ml-2 cursor-pointer"
                />
              ) : (
                <Sclose
                  onClick={() =>
                    handleSetDescriptionExpandedMap("security_storage")
                  }
                  className="inline ml-2 cursor-pointer"
                />
              )}
            </div>
            <input
              id="privacy"
              type="checkbox"
              checked={userPreference.security_storage === "granted"}
              onChange={() => {
                handleToggleCookies("security_storage");
              }}
              className="w-6 h-6 text-blue100  rounded-full 
              focus:ring-yellow  cursor-pointer
              focus:ring-2  ring-offset-yellow accent-yellow
              "
            />
          </div>
          {descriptionExpandedMap.security_storage && (
            <div
              className="text-white70 px-5 pt-2 text-xs"
              style={{ fontFamily: "Onest-Medium" }}
            >
              Enables storage related to security such as authentication
              functionality, fraud prevention, and other user protection.
            </div>
          )}
        </div>

        <div className="py-4">
          <div className="flex text-white70 justify-between px-5">
            <Button onClick={handleRejectCookies} variant="outlined">
              Reject Cookies
            </Button>
            <Button onClick={handleSaveCookies}>Save setting</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
