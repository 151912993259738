/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as InIcon } from "../../assets/images/inIcon.svg";
import { ReactComponent as ChevronRight } from "../../assets/images/chevronRight.svg";
import { content } from "./content";
import Button from "../button/Button";

function TeamCarousel() {
  const isXLScreen = useMediaQuery({ query: "(min-width: 1440px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 833px)" });

  const sizePerPage = useMemo(() => {
    return isMobile ? 2 : isXLScreen ? 8 : 6;
  }, [isXLScreen, isMobile]);

  const [currentPage, setCurrentPage] = useState(0);
  const isNextPageExists = (currentPage + 1) * sizePerPage < content.length;
  const isPrevPageExists = currentPage > 0;

  const handleNextPage = () => {
    if (isNextPageExists) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (isPrevPageExists) {
      setCurrentPage(currentPage - 1);
    }
  };

  const contentItems = useMemo(() => {
    return content.slice(
      currentPage * sizePerPage,
      (currentPage + 1) * sizePerPage
    );
  }, [currentPage, sizePerPage]);

  useEffect(() => {
    if (
      content.length &&
      !content[currentPage * sizePerPage] &&
      currentPage > 0
    ) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, sizePerPage]);

  return (
    <div className="mt-[56px] m-auto mb-10 xl:block md:block">
      <div className="flex md:grid md:grid-rows-2 grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6">
        {contentItems.map((item) => (
          <div
            key={item.name}
            className="max-w-[calc(50%-8px)] w-full md:max-w-full h-[300px] md:h-[340px] xl:h-[400px] relative"
          >
            <div
              style={{ backgroundImage: `url(${item.image})` }}
              className="h-[300px] md:h-[340px] xl:h-[400px] w-full bg-no-repeat bg-cover bg-center absolute rounded-lg"
            />
            <div
              className="relative h-full flex flex-col justify-end p-6"
              style={{
                background:
                  "linear-gradient(180deg, rgba(20, 21, 22, 0) 38.33%, rgba(20, 21, 22, 0.92) 76.46%)",
              }}
            >
              <p
                className="md:text-lg xl:text-2xl text-white100 m-0 truncate text-center"
                style={{
                  fontFamily: "Onest-Medium",
                }}
              >
                {item.name}
              </p>
              <p
                className="text-sm text-white55 mt-0.5 md:mt-1 xl:mt-1.5 mb-0 truncate text-center"
                style={{
                  fontFamily: "Onest-Medium",
                }}
              >
                {item.role}
              </p>
              {item.linkedIn && (
                <div className="flex pt-4 gap-2 ">
                  <a
                    href={`${item.linkedIn}`}
                    target="_blank"
                    rel="noreferrer"
                    className="flex flex-1"
                    aria-label="linkedin account"
                  >
                    <Button
                      variant="outlined"
                      className="bg-white5 flex w-full justify-center"
                    >
                      <InIcon className="h-4" />
                    </Button>
                  </a>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center gap-2 mt-[50px]">
        <Button
          onClick={handlePrevPage}
          disabled={!isPrevPageExists}
          variant="outlined"
          className="h-[40px] w-[40px] flex justify-center items-center"
        >
          <ChevronRight className="rotate-180" />
        </Button>
        <Button
          onClick={handleNextPage}
          disabled={!isNextPageExists}
          variant="outlined"
          className="h-[40px] w-[40px] flex justify-center items-center"
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  );
}

export default TeamCarousel;
