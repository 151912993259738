/// <reference types="react-scripts" />
import "./button.css";

type ButtonProps = {
  onClick?: () => void;
  className?: string;
  size?: "xs" | "sm" | "md" | "lg";
  variant?: "contained" | "outlined";
  disabled?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

const commonStyleClass = {
  xs: "text-sm px-3 py-1.5 text-center",
  sm: "text-sm px-3 py-2.5 text-center",
  md: "text-base px-6 py-3 text-center",
  lg: "text-base px-6 py-4 text-center",
};

const variantStyleClass = {
  contained: "text-black100 contained-btn",
  outlined: "text-white100 outlined-btn",
};

function Button({
  onClick = () => {},
  className = "",
  size = "sm",
  variant = "contained",
  disabled,
  children,
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      type="button"
      aria-label="button"
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      className={`transition-opacity text-sm font-medium rounded-lg ${
        disabled
          ? "opacity-25 hover:opacity-25 cursor-not-allowed btn-disabled"
          : ""
      } ${commonStyleClass[size]} ${variantStyleClass[variant]} ${className}`}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  onClick: () => {},
  className: "",
  size: "sm",
  variant: "contained",
  disabled: false,
};

export default Button;
