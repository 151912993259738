/* eslint-disable react/require-default-props */
/// <reference types="react-scripts" />
import { Link, useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import { items } from "./data";
import Button from "../common/button/Button";
import { ReactComponent as ChevronDown } from "../assets/images/checvronDown.svg";
import { ReactComponent as ChevronUp } from "../assets/images/checvronUpOrange.svg";
import bookCalendlyCall from "../../utils/bookCalendlyCall";
import { CallUserContext } from "../../store";
import { DefaultCallendlyName } from "../../utils/utils";

type MobileMenuItemProps = {
  to: string;
  isActive: boolean;
  text: string;
  onClick: () => void;
  subItems?: {
    link: string;
    text: string;
  }[];
};

function MobileMenuItem({
  to,
  isActive,
  text,
  onClick,
  subItems,
}: MobileMenuItemProps) {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const ChevronArrow = (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}
      className="ml-2 px-2 h-[26px] flex items-center hover:opacity-60 transition-opacity"
    >
      {isSubmenuOpen ? (
        <ChevronUp />
      ) : (
        <ChevronDown
          className={`${isActive ? "text-orange100" : "text-white100"}`}
        />
      )}
    </div>
  );
  return (
    <li className="text-lg">
      <div className="flex items-center justify-end">
        <Link
          onClick={onClick}
          to={to}
          className={`block hover:opacity-60 transition-opacity md:p-0 text-white100 ${
            isActive || isSubmenuOpen ? "text-orange100" : ""
          }`}
        >
          {text}
        </Link>
        {subItems ? ChevronArrow : ""}
      </div>
      {subItems && isSubmenuOpen && (
        <ul className="text-white100 text-sm text-end">
          {subItems.map((subItem) => (
            <li
              key={subItem.link}
              className="mt-4 hover:opacity-60 transition-opacity"
            >
              <Link to={`${to}/${subItem.link}`} onClick={onClick}>
                {subItem.text}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}

function MobileNav({
  onCloseMenu,
  isCallAvailable = true,
}: {
  onCloseMenu: Function;
  isCallAvailable?: boolean;
}) {
  const location = useLocation();
  const context = useContext(CallUserContext);
  const handleBookCallClick = () => {
    context?.setCallUser(DefaultCallendlyName);
    bookCalendlyCall(undefined, "main menu (mobile)");
  };

  return (
    <div
      className="z-20 h-full flex flex-col pb-2 mt-6 lg:hidden overflow-y-auto pr-4 -mr-4"
      style={{ maxHeight: "calc(100vh - 120px)" }}
    >
      <ul
        className="flex flex-col gap-10 items-end"
        style={{
          fontFamily: "Onest",
        }}
      >
        {items.map((item) => (
          <MobileMenuItem
            onClick={() => onCloseMenu()}
            key={item.link}
            to={item.link}
            isActive={location.pathname.includes(item.link)}
            text={item.text}
            subItems={item.subItems}
          />
        ))}
      </ul>

      {isCallAvailable && (
        <>
          <div className="mt-6 border-t border-dashed border-white15" />
          <Button className="mt-6" size="md" onClick={handleBookCallClick}>
            Book a call
          </Button>
        </>
      )}
    </div>
  );
}

export default MobileNav;
