import { useEffect } from "react";
import { toast } from "react-toastify";
import ReactGA from "react-ga";
import Button from "../common/button/Button";
import { callZapierWebhook } from "../../utils/zapierWebhook";

// eslint-disable-next-line react/prop-types
function MailchimpNameEmailForm({ status, message, onValidated }) {
  let email;
  let name;

  useEffect(() => {
    if (status === "error") {
      toast.error(message);
    }
    if (status === "success") {
      toast.success(message);
      ReactGA.event({
        category: "Button",
        action: `Contact form submitted`,
      });
    }
  }, [status]);
  const submit = () => {
    if (email && name && email.value.indexOf("@") > -1) {
      callZapierWebhook(
        JSON.stringify({
          name: name.value,
          email: email.value,
          fromPage: window.location.href,
        })
      );
      onValidated({
        EMAIL: email.value,
        NAME: name.value,
      });
    } else {
      toast.warning("Please enter valid data");
    }
  };

  return (
    <div
      style={{
        backdropFilter: "blur(96px)",
        boxShadow: "0px 16px 56px 0px #000000",
      }}
      className="mt-[56px] flex flex-col md:flex-row gap-4 bg-[#292e37] px-3 md:pl-6 py-3 rounded-lg"
    >
      <input
        className="w-full bg-transparent autofill:bg-transparent text-white border-none focus:outline-none focus:ring-0 placeholder-[#F1F8FF66] border-b-[3px] text-base"
        ref={(node) => {
          name = node;
        }}
        type="name"
        placeholder="First Name"
      />
      <div className="border-t md:border-t-0 md:border-r border border-white10 min-h-full md:mx-4" />
      <input
        className="w-full bg-transparent autofill:bg-transparent text-white border-none focus:outline-none focus:ring-0 placeholder-[#F1F8FF66] border-b-[3px] text-base"
        ref={(node) => {
          email = node;
        }}
        type="email"
        placeholder="Email"
      />
      <div className="border-t md:border-t-0 md:border-r border border-white10 min-h-full md:mx-4" />
      <Button onClick={submit} className="whitespace-nowrap">
        Join the Communuty
      </Button>
    </div>
  );
}

export default MailchimpNameEmailForm;
