import { useEffect } from "react";
import { toast } from "react-toastify";
import ReactGA from "react-ga";
import Button from "../common/button/Button";
import { callZapierWebhook } from "../../utils/zapierWebhook";

// eslint-disable-next-line react/prop-types
function MailchimpEmailForm({ status, message, onValidated }) {
  let email;

  useEffect(() => {
    if (status === "error") {
      toast.error(message);
    }
    if (status === "success") {
      toast.success(message);
      ReactGA.event({
        category: "Button",
        action: `Contact form submitted`,
      });
    }
  }, [status]);
  const submit = () => {
    if (email && email.value.indexOf("@") > -1) {
      callZapierWebhook(
        JSON.stringify({
          email: email.value,
          fromPage: window.location.href,
        })
      );
      onValidated({
        EMAIL: email.value,
      });
    } else {
      toast.warning("Please enter a valid email");
    }
  };

  return (
    <div
      style={{
        backdropFilter: "blur(96px)",
        boxShadow: "0px 16px 56px 0px #000000",
      }}
      className="flex flex-col md:flex-row gap-4 md:max-w-[544px] bg-[#292e37] px-3 md:pl-6 py-3 rounded-lg"
    >
      <input
        className="w-full bg-transparent autofill:bg-transparent text-white border-none focus:outline-none focus:ring-0 placeholder-[#6b7f87] border-b-[3px] text-base"
        ref={(node) => {
          email = node;
        }}
        type="email"
        placeholder="Enter your email here"
      />
      <Button onClick={submit} className="whitespace-nowrap">
        Join the Communuty
      </Button>
    </div>
  );
}

export default MailchimpEmailForm;
