import ReactGA from "react-ga";

const bookCalendlyCall = (
  urlPostfix: string | undefined = "alejandro-protofire", // def for DefaultCallendlyName
  reactGALable: string | undefined = "common"
) => {
  (window as any).Calendly.initPopupWidget({
    url: `https://calendly.com/${urlPostfix}`,
  });
  ReactGA.event({
    category: "Button",
    action: "Book a call click",
    label: reactGALable,
  });
  return false;
};
export default bookCalendlyCall;
