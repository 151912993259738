import axios from "axios";

/* eslint-disable import/prefer-default-export */
const webhookZapierUrl =
  "https://hooks.zapier.com/hooks/catch/20977274/2sdige2/";

export const callZapierWebhook = (formData: string) =>
  axios.post(webhookZapierUrl, formData, {
    withCredentials: false,
    transformRequest: [
      (data, headers: any) => {
        // eslint-disable-next-line no-param-reassign
        delete headers.post["Content-Type"];
        return data;
      },
    ],
  });
