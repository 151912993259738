import { toast, ToastContainer } from "react-toastify";
import React, { useState } from "react";
import axios from "axios";
import ReactGA from "react-ga";
import { callZapierWebhook } from "../../../utils/zapierWebhook";
/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line jsx-a11y/label-has-associated-control

function ContactForm() {
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [modalOpen, setModalOpen] = useState(false);

  const notify = (message: string) => toast(message);
  const isValid = Object.values(form).every((value) => value.length > 0);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.currentTarget;
    setForm({ ...form, [name]: value });
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (!isValid) return notify("Make sure that all fields are filled out");
    const webhookUrl = `${process.env.REACT_APP_SOLHINT_WEBHOOK_URL}`;

    const formData = {
      text: `Name: ${form.name} \n
      Email: ${form.email} \n
      Message: ${form.message}`,
    };
    // if (!privacyPolicy) return notify("Not sent, please accept privacy policy");

    callZapierWebhook(
      JSON.stringify({
        name: form.name,
        email: form.email,
        fullMessage: form.message,
        fromPage: window.location.href,
      })
    );

    const res = await axios.post(webhookUrl, JSON.stringify(formData), {
      withCredentials: false,
      transformRequest: [
        (data, headers: any) => {
          // eslint-disable-next-line no-param-reassign
          delete headers.post["Content-Type"];
          return data;
        },
      ],
    });

    if (res.status === 200) {
      notify("Message Successfully sent");
      ReactGA.event({
        category: "Button",
        action: `Contact form submitted by ${form.name}`,
      });
      setForm({
        name: "",
        email: "",
        message: "",
      });
    } else {
      notify("Not sent, please check your entries");
    }
  };

  return (
    <form style={{ fontFamily: "Inter" }}>
      <ToastContainer />
      <div className="w-full mb-6">
        <label
          htmlFor="name"
          className="text-solhintGray text-[14px] leading-[20px]"
        >
          Name
        </label>
        <input
          type="text"
          name="name"
          id="name"
          className="w-full py-[14px] px-[10px] bg-transparent autofill:bg-transparent text-solhintGray border-[#282A37] placeholder-solhintGray border-[1px] rounded-[5px]"
          placeholder="Name"
          onChange={handleChange}
          value={form.name}
          required
        />
      </div>

      <div className="w-full mb-6">
        <label
          htmlFor="email"
          className="text-solhintGray text-[14px] leading-[20px]"
        >
          Email
        </label>
        <input
          type="email"
          name="email"
          id="email"
          className="w-full py-[14px] px-[10px] bg-transparent autofill:bg-transparent text-solhintGray border-[#282A37] placeholder-solhintGray border-[1px] rounded-[5px]"
          placeholder="you@company.com"
          onChange={handleChange}
          value={form.email}
          required
        />
      </div>

      <div className="w-full mb-4">
        <label
          htmlFor="message"
          className="text-solhintGray text-[14px] leading-[20px]"
        >
          Message
        </label>
        <textarea
          name="message"
          id="message"
          className="w-full py-[14px] px-[10px] bg-transparent autofill:bg-transparent text-solhintGray border-[#282A37] placeholder-solhintGray border-[1px] rounded-[5px]"
          placeholder="Leave us a message..."
          rows={4}
          onChange={handleChange}
          value={form.message}
          required
        />
      </div>
      <div className="w-full mb-6 flex align-middle">
        <input
          id="privacy"
          type="checkbox"
          // checked={privacyPolicy}
          // onChange={handlePrivacy}
          className="w-4 h-4 text-[#2f57ff]rounded focus:ring-[#2f57ff] focus:ring-2  ring-offset-[#b52efe] accent-[#b52efe]"
        />
        <label
          htmlFor="privacy"
          className="ml-2 text-solhintGray text-[14px] leading-[20px]"
        >
          I accept the{" "}
          <button
            type="button"
            className="bg-gradient-to-r from-[#3356ff] to-[#b32dfe] text-transparent bg-clip-text"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Privacy Policy
          </button>
        </label>
      </div>
      <button
        type="button"
        className="text-white bg-gradient-to-r from-[#2f57ff] to-[#b52efe] rounded-[5px] text-[16px] leading-[24px] py-4 px-10 inline-block cursor-pointer"
        onClick={handleSubmit}
      >
        Send Message
      </button>
    </form>
  );
}

export default ContactForm;
