/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Helmet } from "react-helmet";
import { useMemo, useState } from "react";
import projectsBg from "../assets/projects/bgMain1.png";
import cardanoProjects from "../assets/projects/cardanoProjects.png";
import { projectsCardano, tagsCardano } from "./data";
import StudyProjectCard from "../common/studyProjectCard";
import ToggleTag from "../common/offersSelectorControl/ToggleTag";
import federico from "../assets/team/federico.png";
import BookCallWith from "../common/bookCallWith";
import cristian from "../assets/team/cristian.png";
import diego from "../assets/team/diegoCardano.png";
import agustin from "../assets/team/agustin.png";
import francisco from "../assets/team/francisco.png";
import gerardo from "../assets/team/gerardo.png";
import manuel from "../assets/team/manuel.png";
import Button from "../common/button/Button";
import { ReactComponent as InIcon } from "../assets/images/inIcon.svg";

const teamCardano = [
  {
    name: "Agustin Franchella",
    role: "DAO Rep",
    linkedIn: "https://www.linkedin.com/in/afranchella/",
    image: agustin,
    imageAlt: "Agustin Franchella",
    key: 1,
  },
  {
    name: "Cristian Malfesi",
    role: "Director of Engineering",
    linkedIn: "https://www.linkedin.com/in/cristian-malfesi/",
    image: cristian,
    imageAlt: "Cristian Malfesi, Director of Engineering at Protofire",
    key: 2,
  },
  {
    name: "Diego Torres",
    role: "Field CTO",
    linkedIn: "https://www.linkedin.com/in/diego-torres-borda-94b70912/",
    image: diego,
    imageAlt: "Diego Torres",
    key: 3,
  },
  {
    name: "Federico Ledesma",
    role: "Project Manager",
    linkedIn: "https://www.linkedin.com/in/lic-ledesma-calatayud/",
    image: federico,
    imageAlt: "Federico Ledesma",
    key: 4,
  },
  {
    name: "Francisco Ciordia",
    role: "Developer",
    linkedIn:
      "https://www.linkedin.com/in/francisco-ciordia-cantarella-5323461b8/",
    image: francisco,
    imageAlt: "Francisco Ciordiae",
    key: 5,
  },
  {
    name: "Gerardo Barboni",
    role: "UX/UI",
    linkedIn: "https://www.linkedin.com/in/gerardobarboni/",
    image: gerardo,
    imageAlt: "Gerardo Barboni",
    key: 6,
  },
  {
    name: "Manuel Padilla",
    role: "Developer",
    linkedIn: "https://www.linkedin.com/in/manuel-ale-padilla/",
    image: manuel,
    imageAlt: "Manuel Padilla",
    key: 7,
  },
];

function CardanoProjects() {
  const [selectedTag, setSelectedTag] = useState<string>(tagsCardano[0]);

  const filteredProjects = useMemo(() => {
    if (selectedTag === tagsCardano[0]) {
      return [
        ...projectsCardano.Delivered,
        ...projectsCardano["Proposals F13"],
      ];
    }
    return projectsCardano[selectedTag];
  }, [selectedTag]);

  return (
    <div
      className="bg-no-repeat bg-auto"
      style={{ backgroundImage: `url(${projectsBg})` }}
    >
      <div className="max-w-[1120px] mx-auto pt-[80px]">
        <Helmet>
          <title>
            Protofire: Advancing Cardano with Innovative Blockchain Solutions
          </title>
          <meta
            name="description"
            content="Explore Protofire's cutting-edge Cardano projects, from Developer Studio to UTXO DB Abstraction, driving ecosystem growth and adoption."
          />
          <meta
            name="keywords"
            content="Cardano, blockchain solutions, Protofire, smart contracts, DAppsCompleted"
          />
        </Helmet>
        <section className="mx-4 md:mx-6 xl:mx-0 flex flex-row">
          <div className="md:max-w-[544px] pt-[100px] md:pt-[120px] text-white100 pb-[120px] md:pb-[150px]">
            <h1
              className="text-[32px] leading-[40px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px]"
              style={{ fontFamily: "Onest-SemiBold" }}
            >
              Our Cardano Projects
            </h1>
            <p className="mt-4 mb-0 text-base md:text-lg">
              We are committed to advancing the Cardano ecosystem by
              transforming innovative ideas into real-world blockchain
              solutions. Through our technical expertise and strategic vision,
              we deliver projects that not only meet our partners&apos; goals
              but also strengthen and expand Cardano’s potential, fostering
              growth and adoption across industries.
            </p>
          </div>
          <div
            className="flex-1 bg-no-repeat bg-left bg-cover min-h-[400px] max-h-[550px] max-w-[550px] hidden xl:block"
            style={{
              backgroundImage: `url(${cardanoProjects})`,
            }}
          />
        </section>
        <section className="mb-[120px] md:mb-[150px] mx-4 md:mx-6 xl:mx-0">
          <h3 className="mb-3 md:mb-5 text-base md:text-2xl text-white100">
            Our contributions
          </h3>
          {tagsCardano.map((tag) => (
            <ToggleTag
              clasName="mr-2 mt-2"
              key={tag}
              onClick={() => {
                if (tag !== selectedTag) {
                  setSelectedTag(tag);
                }
              }}
              checked={tag === selectedTag}
              text={tag}
            />
          ))}
          <div className="flex flex-col xl:flex-row mt-[48px] gap-8">
            <div className="flex-1 text-white100 rounded-lg bg-white5 p-8 text-[24px] leading-[32px] md:text-[32px] md:leading-[40px]">
              Explore our contributions delivered and to be developed
            </div>
            <div className="flex-[2] text-black100 bg-orange100 rounded-lg p-8">
              <p className="text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px]">
                Proposals F13
              </p>
              <p
                className="text-[16px] leading-[24px] md:text-[18px] md:leading-[26px] xl:text-[24px] xl:leading-[32px] mt-8"
                style={{ fontFamily: "Onest-Light" }}
              >
                These are our proposals for{" "}
                <a
                  href="https://x.com/Catalyst_onX"
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontFamily: "Onest-Bold" }}
                >
                  @catalyst_onX{" "}
                </a>
                in F13, we appreciate your support by voting for them.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:grid grid-cols-6 gap-8 mt-12">
            {filteredProjects.map((projectItem) => (
              <StudyProjectCard
                key={projectItem.id}
                imgSrc={projectItem.img}
                title={projectItem.title}
                tagList={projectItem.tagList}
                text={projectItem.text}
                link={projectItem.link}
                className="col-span-6 md:col-span-3"
              />
            ))}
          </div>

          <h2
            className="text-white100 text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px] mt-[120px] mb-[56px]"
            style={{ fontFamily: "Onest-SemiBold" }}
          >
            Meet our team at Cardano
          </h2>
          <div className="grid md:grid-rows-2 grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6">
            {teamCardano.map((item) => (
              <div
                key={item.name}
                className="w-full md:max-w-full h-[300px] md:h-[340px] xl:h-[400px] relative"
              >
                <div
                  style={{ backgroundImage: `url(${item.image})` }}
                  className="h-[300px] md:h-[340px] xl:h-[400px] w-full bg-no-repeat bg-cover bg-center absolute rounded-lg"
                />
                <div
                  className="relative h-full flex flex-col justify-end p-6"
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(20, 21, 22, 0) 38.33%, rgba(20, 21, 22, 0.92) 76.46%)",
                  }}
                >
                  <p
                    className="md:text-lg xl:text-2xl text-white100 m-0 truncate text-center"
                    style={{
                      fontFamily: "Onest-Medium",
                    }}
                  >
                    {item.name}
                  </p>
                  <p
                    className="text-sm text-white55 mt-0.5 md:mt-1 xl:mt-1.5 mb-0 truncate text-center"
                    style={{
                      fontFamily: "Onest-Medium",
                    }}
                  >
                    {item.role}
                  </p>
                  {item.linkedIn && (
                    <div className="flex pt-4 gap-2 ">
                      <a
                        href={`${item.linkedIn}`}
                        target="_blank"
                        rel="noreferrer"
                        className="flex flex-1"
                        aria-label="linkedin account"
                      >
                        <Button
                          variant="outlined"
                          className="bg-white5 flex w-full justify-center"
                        >
                          <InIcon className="h-4" />
                        </Button>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="mt-[120px] md:mt-[150px]">
            <section className="mx-4 md:mx-6 xl:mx-0">
              <div className="xl:max-w-[832px]">
                <h2
                  style={{ fontFamily: "Onest-SemiBold" }}
                  className="text-[32px] leading-[40px] md:text-[40px] md:leading-[48px] xl:text-[48px] xl:leading-[56px] text-white100"
                >
                  Do you want to create something in Cardano?
                </h2>
                <p className="mt-4 py-0 text-base xl:text-lg text-white55">
                  So that we can discuss your ideas.
                </p>
              </div>

              <BookCallWith
                className="mt-[100px]"
                imgUrl={federico}
                name="Federico Ledesma"
                lnUrl="https://www.linkedin.com/in/lic-ledesma-calatayud/"
                tgUrl="https://t.me/federicoledesma"
                calendlyCallPostfix="federico-ledesma-protofire/30min"
                text="Schedule a call with our Project Manager to receive practical recommendations and a prompt proposal for upgrading your solution."
              />
            </section>
          </div>
        </section>
      </div>
    </div>
  );
}

export default CardanoProjects;
