/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/home/arrow-right.svg";
import Button from "../button/Button";
import "./cards.css";

type StudyProjectCardProps = {
  imgSrc?: string;
  title: string;
  text: string;
  tagList: string[];
  link: string;
  className?: string;
};

function TagLabel({
  isExtra,
  children,
}: {
  isExtra?: boolean;
  children: React.ReactNode;
}) {
  return (
    <div
      className={`text-[10px] leading-[16px] px-3 py-2  rounded-2xl mr-2 mt-1.5 ${
        isExtra ? "text-black100 bg-orange100" : "text-white100 bg-black40"
      }`}
    >
      {children}
    </div>
  );
}

function StudyProjectCard({
  imgSrc,
  title,
  text,
  tagList,
  className,
  link,
}: StudyProjectCardProps) {
  return (
    <div
      className={`flex flex-col rounded-lg bg-white5 w-full ${className}`}
      style={{ backdropFilter: "blur(160px)" }}
    >
      <div
        style={{
          backgroundImage: imgSrc
            ? `url(${imgSrc})`
            : "linear-gradient(270deg, #D48050 0%, #344880 32.98%, #030308 100%)",
        }}
        className="h-[200px] w-full flex items-center justify-center bg-cover bg-center bg-no-repeat rounded-t-lg px-6 pb-2 flex-wrap relative"
      >
        {!imgSrc && (
          <h3 className="text-[#696764] text-center text-[24px] leading-[27px]">
            {title}
          </h3>
        )}
        <div className="flex flex-wrap-reverse absolute bottom-2 left-6">
          {tagList.slice(0, 3).map((item) => (
            <TagLabel key={item} isExtra={!imgSrc}>
              {item}
            </TagLabel>
          ))}
        </div>
      </div>
      <div className="p-6 pt-4 flex flex-col justify-between flex-1">
        <h3 className="text-white100 text-[32px] leading-[40px]">{title}</h3>
        <div>
          <p className="text-white55 text-sm mt-2 mb-0 overflow-hidden stydy-project-card-text">
            {text}
          </p>
          <Link
            to={link}
            className="w-full xl:w-auto inline-block mt-6"
            aria-label="project-link"
          >
            <Button
              variant="outlined"
              className="w-full xl:w-auto py-[11px] px-[28px] flex items-center justify-center"
            >
              <ArrowRight />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default StudyProjectCard;
