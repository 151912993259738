/* eslint-disable react/prop-types */
/// <reference types="react-scripts" />
import StudyProjectCard from "../../common/studyProjectCard";
import bnbToPolygon from "../../assets/projects/BNB-to-Polygon-Bridging-for-Kryptomon.png";
import diaOracles from "../../assets/projects/DIA-Oracles-on-Kadena.png";
import cardanoProjectsTile from "../../assets/projects/cardanoProjectsTile.png";
import contractExplorer from "../../assets/projects/contract-explorer.png";
import developerStudioV2 from "../../assets/projects/developer-studio-v2.png";
import vanaBanner from "../../assets/projects/vanaBanner.png";

function StudiesProjectsCards() {
  return (
    <div className="mt-14 grid grid-cols-6 gap-8">
      <StudyProjectCard
        imgSrc={vanaBanner}
        title="Vana Staking dApp"
        tagList={["dApp", "Platform"]}
        text="Protofire developed and implemented a staking dApp for $VANA, the native token of Vana's decentralized AI data protocol. This project includes a user-friendly web application and subgraph integrations, enabling $VANA holders to stake their tokens, support Data Liquidity Pools (DLPs), and earn rewards."
        link="/projects/vana"
        className="col-span-6 md:col-span-3 xl:col-span-2"
      />
      <StudyProjectCard
        imgSrc={cardanoProjectsTile}
        title="Cardano Projects"
        tagList={["Delivered", "Proposals F13"]}
        text="We are committed to advancing the Cardano ecosystem by transforming innovative ideas into real-world blockchain solutions. Through our technical expertise and strategic vision, we deliver projects that not only meet our partners' goals but also strengthen and expand Cardano’s potential, fostering growth and adoption across industries."
        link="/projects/cardano-projects"
        className="col-span-6 md:col-span-3 xl:col-span-2"
      />
      <StudyProjectCard
        imgSrc={contractExplorer}
        title="Protofire - Contract Explorer"
        tagList={["Smart Contracts", "F13"]}
        text="This project aims to enhance transparency and trust within the Cardano ecosystem by creating a platform that allows users to explore, verify, and understand smart contracts, minting policies, and their associated attributes. By offering tools for developers and users alike, this initiative bridges the gap between technical complexity and user comprehension, fostering engagement and security within the network."
        link="/projects/cardano-projects/contract-explorer"
        className="col-span-6 md:col-span-3 xl:col-span-2"
      />
      <StudyProjectCard
        imgSrc={developerStudioV2}
        title="Protofire - Developer Studio v2"
        tagList={["DevTools", "F13"]}
        text="Cardano Developer Studio v2 (Dev Studio v2) represents a groundbreaking upgrade to the original Developer Studio, providing a comprehensive development environment tailored for Cardano smart contract creation. Designed for efficiency and collaboration, Dev Studio v2 integrates with Visual Studio Code, simplifying the entire lifecycle of smart contract development from setup to deployment, all while being fully open-source."
        link="/projects/cardano-projects/developer-studio-v2"
        className="col-span-6 md:col-span-3"
      />
      <StudyProjectCard
        imgSrc={diaOracles}
        title="DIA Oracles on Kadena"
        tagList={["Oracle", "Smart Contract"]}
        text="Protofire integrated DIA oracles on Kadena, enabling 14 asset price feeds for blockchain protocols. Learn about our innovative approach to expanding oracle services and enhancing DeFi capabilities on the Kadena network."
        link="/projects/dia-oracles-kadena"
        className="col-span-6 md:col-span-3"
      />
      <StudyProjectCard
        imgSrc={bnbToPolygon}
        title="BNB-to-Polygon Bridging for Kryptomon"
        tagList={["CCIP", "Integration"]}
        text="Protofire has leveraged Chainlink's CCIP to create a secure, one-time, and one-direction bridge infrastructure to transfer the Kryptomon assets (ERC20 and NFTs) from BNB to Polygon chain using an innovative approach to blockchain interoperability and asset transfer for gaming ecosystems."
        link="/projects/dia-oracles-kadena"
        className="col-span-6 md:col-span-3 xl:hidden"
      />
    </div>
  );
}

export default StudiesProjectsCards;
