/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import TextField from "../common/textField/TextField";
import alejandro from "../assets/team/alejandro.png";
import Button from "../common/button/Button";
import { ReactComponent as InIcon } from "../assets/images/inIcon.svg";
import Checkbox from "../common/checkboxs/Checkbox";
import { callZapierWebhook } from "../../utils/zapierWebhook";

// type ContactProps = {
//   contactName: string;
//   linkedin: string;
//   telegram: string;
//   contactRole: string;
//   image: string;
// };

function ContactUs() {
  const phoneRegex =
    /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    telegram: "",
    fullMessage: "",
  });
  const [contactViaMessengers, setContactViaMessengers] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [receiveNews, setReceiveNews] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.currentTarget;
    setForm({ ...form, [name]: value });
  };
  const handleMessengers = () => {
    setContactViaMessengers(!contactViaMessengers);
  };
  const handlePrivacy = () => {
    setPrivacy(!privacy);
  };
  const handleReceiveNews = () => {
    setReceiveNews(!receiveNews);
  };
  const isValidPhone = form.phone && phoneRegex.test(form.phone);
  const isValidEmail = form.email && emailRegex.test(form.email);
  const isValid = isValidEmail || isValidPhone || form.telegram;
  const notify = (message: string) => toast(message);

  // eslint-disable-next-line consistent-return
  const handleSubmit = async () => {
    if (!isValid)
      return notify("At least one fields should be filled in correctly");
    const webhookUrl = `${process.env.REACT_APP_WEBHOOK_URL}`;

    const formData = {
      text: `Name: ${form.name} \n
      TelegramId: ${form.telegram} \n
      Phone: ${form.phone} \n
      Email: ${form.email} \n
      Full Message: ${form.fullMessage} \n
      Prefer via messengers: ${contactViaMessengers ? "yes" : "no"} \n
      Agree to receive new research papers: ${receiveNews ? "yes" : "no"} \n
      ${window.location.href}`,
    };
    if (!privacy) return notify("not sent, please accept  privacy policy");

    callZapierWebhook(
      JSON.stringify({
        name: form.name,
        email: form.email,
        phone: form.phone,
        telegram: form.telegram,
        fullMessage: form.fullMessage,
        fromPage: window.location.href,
        preferContactViaMessengers: contactViaMessengers,
        agreeToReceiveNews: receiveNews,
      })
    );

    await axios
      .post(webhookUrl, JSON.stringify(formData), {
        withCredentials: false,
        transformRequest: [
          (data, headers: any) => {
            delete headers.post["Content-Type"];
            return data;
          },
        ],
      })
      .then((res) => {
        if (res.status === 200) {
          notify("Message Successfully sent");
          ReactGA.event({
            category: "Button",
            action: `Contact form submitted by  ${form.name}`,
          });
          setForm({
            name: "",
            phone: "",
            email: "",
            telegram: "",
            fullMessage: "",
          });
        } else {
          notify("Not sent, please check your entries");
        }
      })
      .catch((error) => {
        console.log(error);
        notify("Something went wrong...");
      });
  };

  return (
    <form
      className="rounded-xl p-6 md:p-8"
      style={{
        // fontFamily: "Onest",
        background:
          "linear-gradient(180deg, rgba(153, 153, 153, 0) 0%, rgba(255, 255, 255, 0.08) 100%)",
      }}
    >
      <ToastContainer />
      <div className="flex flex-col-reverse md:flex-row w-full md:gap-[56px]">
        <div className="flex flex-col gap-8 flex-1">
          <div className="mt-8 md:mt-0">
            <h2
              className="text-[32px] leading-[40px] text-white100 mb-2"
              style={{
                fontFamily: "Onest-Medium",
              }}
            >
              Contact Us
            </h2>
            <p className="text-white55 text-lg">
              We will respond to you with lightning speed
            </p>
          </div>
          <div className="flex flex-col xl:flex-row gap-8">
            <TextField
              value={form.name}
              onChange={handleChange}
              label="Full Name"
              name="name"
              id="fullName"
            />
            <TextField
              value={form.email}
              onChange={handleChange}
              label="Email"
              name="email"
              id="email"
            />
          </div>
          <div className="flex flex-col xl:flex-row gap-8">
            <TextField
              value={form.phone}
              onChange={handleChange}
              label="Phone number"
              name="phone"
              id="phone"
            />
            <TextField
              value={form.telegram}
              onChange={handleChange}
              label="Telegram ID"
              name="telegram"
              id="telegram"
            />
          </div>
          <TextField
            value={form.fullMessage}
            onChange={handleChange}
            label="How can we help you?"
            name="fullMessage"
            id="fullMessage"
            isMultiRow
          />
        </div>

        <div className="flex flex-col rounded-xl bg-white5 p-8 items-center justify-center">
          <div
            className="min-h-[184px] min-w-[184px] max-h-[184px] max-w-[184px] bg-center bg-no-repeat bg-cover rounded-full"
            style={{
              backgroundImage: `url(${alejandro})`,
            }}
          />
          <div className="mt-6 flex flex-col items-center">
            <p className="mb-1 mt-0 text-base text-white55">
              Hi, my name&apos;s
            </p>
            <p
              className="mt-0 mb-6 text-xl text-white100"
              style={{ fontFamily: "Onest-Medium" }}
            >
              Alejandro Losa
            </p>
            <a
              href="https://www.linkedin.com/in/alejandrolosa/"
              target="_blank"
              rel="noreferrer"
              className="flex"
            >
              <Button
                variant="outlined"
                className="bg-white5 flex justify-center items-center min-w-[120px] min-h-[56px] w-full md:w-auto"
              >
                <InIcon className="h-4" />
              </Button>
            </a>
          </div>
        </div>
      </div>

      <div className="max-w-[650px] text-sm text-white55 mt-8">
        <div className="flex gap-2 flex-row items-center">
          <Checkbox
            onClick={handleMessengers}
            checked={contactViaMessengers}
            className="max-w-[24px] max-h-[24px] min-w-[24px] min-h-[24px]"
          />
          <div
            className="my-0"
            role="button"
            onClick={handleMessengers}
            tabIndex={0}
            onKeyDown={() => {}}
          >
            I prefer to be contacted via messengers
          </div>
        </div>
        <div className="flex gap-2 flex-row items-center mt-4">
          <Checkbox
            onClick={handlePrivacy}
            checked={privacy}
            className="max-w-[24px] max-h-[24px] min-w-[24px] min-h-[24px]"
          />
          <div
            className="my-0"
            role="button"
            onClick={handlePrivacy}
            tabIndex={0}
            onKeyDown={() => {}}
          >
            I accept the
          </div>
          <Link
            to="/privacy-policy"
            target="_blank"
            className="underline underline-offset-2 text-orange100 -ml-1 hover:opacity-60 transition-opacity"
          >
            Privacy Policy
          </Link>
        </div>
        <div className="flex gap-2 flex-row items-center mt-4">
          <Checkbox
            onClick={handleReceiveNews}
            checked={receiveNews}
            className="max-w-[24px] max-h-[24px] min-w-[24px] min-h-[24px]"
          />
          <div
            className="my-0"
            role="button"
            onClick={handleReceiveNews}
            tabIndex={0}
            onKeyDown={() => {}}
          >
            I agree to receive new research papers announcements as well as
            information about Protofire services and special offers
          </div>
        </div>
      </div>
      <Button
        size="lg"
        onClick={handleSubmit}
        disabled={!privacy}
        className="mt-8"
      >
        Send Message
      </Button>
    </form>
  );
}

export default ContactUs;
