import aave from "../assets/partners/aave.png";
import alephium from "../assets/partners/Alephium1.png";
import algorand from "../assets/partners/Algorand_full_logo_white.png";
import astar from "../assets/partners/Astar-astr-logo.png";
import autonomys from "../assets/partners/Autonomys.png";
import balancer from "../assets/partners/balancer-bal-logo.png";
import benqi from "../assets/partners/Benqi.png";
import bifrost2 from "../assets/partners/Bifrost2.png";
import blast1 from "../assets/partners/Blast1.png";
import boba from "../assets/partners/Boba.png";
import cascadia from "../assets/partners/cascadia.png";
import connext from "../assets/partners/Connext.png";
import cronos from "../assets/partners/Cronos.png";
import cyber from "../assets/partners/cyber.png";
import deltaPrime from "../assets/partners/DeltaPrime.png";
import ethereumFoundation from "../assets/partners/Ethereum_Foundation.png";
import etherlink from "../assets/partners/Etherlink.png";
import ffineryMarkets2 from "../assets/partners/Ffinery_markets2.png";
import fil from "../assets/partners/fil.png";
import fineryMarkets1 from "../assets/partners/Finery-Markets1.png";
import flareFlrLogo from "../assets/partners/flare-flr-logo.png";
import frame21 from "../assets/partners/Frame 2-1.png";
import frame22 from "../assets/partners/Frame 2-2.png";
import frame23 from "../assets/partners/Frame 2-3.png";
import frame2 from "../assets/partners/Frame 2.png";
import fraxtal2 from "../assets/partners/Fraxtal2.png";
import fuel2 from "../assets/partners/Fuel2.png";
import fuse from "../assets/partners/Fuse2.png";
import gelato from "../assets/partners/Gelato.png";
import giddy2 from "../assets/partners/Giddy2.png";
import harmonyOneLogo from "../assets/partners/harmony-one-logo.png";
import immutableX from "../assets/partners/Immutable-x-imx-logo.png";
import iotx from "../assets/partners/iotx.png";
import ipor1 from "../assets/partners/IPOR1.png";
import kadenaKdaLogo from "../assets/partners/kadena-kda-logo.png";
import kakarot1 from "../assets/partners/Kakarot1.png";
import kava from "../assets/partners/Kava.png";
import kinetix from "../assets/partners/Kinetix.png";
import kroma from "../assets/partners/Kroma.png";
import kryptomon from "../assets/partners/Kryptomon.png";
import landx from "../assets/partners/LandX.png";
import lavaLabs from "../assets/partners/LavaLabs.png";
import lavaLabs1 from "../assets/partners/LavaLabs1.png";
import lineaLogo from "../assets/partners/Linea-logo-png.png";
import link from "../assets/partners/link.png";
import lisk2 from "../assets/partners/Lisk2.png";
import logoWhite from "../assets/partners/logoWhite.png";
import mantle1 from "../assets/partners/Mantle1.png";
import mkr from "../assets/partners/mkr.png";
import mode from "../assets/partners/mode.png";
import monolith2 from "../assets/partners/Monolith2.png";
import moonbeam from "../assets/partners/moonbeam.png";
import morphBanner from "../assets/partners/Morph_Banner.png";
import neon from "../assets/partners/neon.png";
import oasisNetworkRoseLogo from "../assets/partners/oasis-network-rose-logo.png";
import openCampusEduLogo from "../assets/partners/open-campus-edu-logo.png";
import optimismEthereumOpLogo from "../assets/partners/optimism-ethereum-op-logo.png";
import patchWallet from "../assets/partners/PatchWallet.png";
import polkadotNewDotLogo from "../assets/partners/polkadot-new-dot-logo.png";
import projectLogoTenet from "../assets/partners/project_logo_tenet.png";
import quickswapLogo from "../assets/partners/quickswap-logo.png";
import redstone from "../assets/partners/redstone.png";
import reya from "../assets/partners/reya.png";
import rootstock from "../assets/partners/Rootstock.png";
import saakuru1 from "../assets/partners/Saakuru1.png";
import saga from "../assets/partners/Saga.png";
import sei from "../assets/partners/sei.png";
import solanaSolLogo from "../assets/partners/solana-sol-logo.png";
import sophon from "../assets/partners/Sophon.png";
import soroban from "../assets/partners/Soroban.png";
import stacksStxLogo from "../assets/partners/stacks-stx-logo.png";
import stader from "../assets/partners/Stader.png";
import starknetTokenStrkLogo from "../assets/partners/starknet-token-strk-logo.png";
import stratos from "../assets/partners/Stratos.png";
import superchain from "../assets/partners/superchain.png";
import superrare3 from "../assets/partners/Superrare3.png";
import superseedWhite from "../assets/partners/SuperseedL_white.png";
import taiko from "../assets/partners/taiko.png";
import telos1 from "../assets/partners/Telos1.png";
import tenet1 from "../assets/partners/Tenet1.png";
import thunderCoreLogoMark from "../assets/partners/ThunderCore_Logo_Mark.png";
import truflation1 from "../assets/partners/Truflation1.png";
import vana from "../assets/partners/vana.png";
import vector1 from "../assets/partners/Vector-1.png";
import vector2 from "../assets/partners/Vector-2.png";
import vector3 from "../assets/partners/Vector-3.png";
import vector4 from "../assets/partners/Vector-4.png";
import vector5 from "../assets/partners/Vector-5.png";
import vector from "../assets/partners/Vector.png";
import velasVlxLogo from "../assets/partners/velas-vlx-logo.png";
import vineMoney from "../assets/partners/VineMoney_.png";
import wemixLogo from "../assets/partners/wemix-logo.png";
import xrpXrpLogo from "../assets/partners/xrp-xrp-logo1.png";
import ygro from "../assets/partners/YGRO.png";
import ynthtetix from "../assets/partners/ynthetix.png";
import zeebu from "../assets/partners/Zeebu.png";
import zetachain from "../assets/partners/Zetachain.png";
import zilliqaZilLogo from "../assets/partners/zilliqa-zil-logo.png";
import zkscroll1 from "../assets/partners/zkscroll1.png";
import zora from "../assets/partners/Zora.png";

// eslint-disable-next-line import/prefer-default-export
export const partnersLogos = [
  {
    src: aave,
    name: "aave",
  },
  {
    src: alephium,
    name: "alephium",
  },
  {
    src: algorand,
    name: "algorand",
  },
  {
    src: astar,
    name: "astar",
  },
  {
    src: autonomys,
    name: "autonomys",
  },
  {
    src: balancer,
    name: "balancer",
  },
  {
    src: bifrost2,
    name: "bifrost2",
  },
  {
    src: benqi,
    name: "benqi",
  },
  {
    src: blast1,
    name: "blast1",
  },
  {
    src: boba,
    name: "boba",
  },
  {
    src: cascadia,
    name: "cascadia",
  },
  {
    src: connext,
    name: "connext",
  },
  {
    src: cronos,
    name: "cronos",
  },
  {
    src: cyber,
    name: "cyber",
  },
  {
    src: deltaPrime,
    name: "deltaPrime",
  },
  {
    src: ethereumFoundation,
    name: "ethereumFoundation",
  },
  {
    src: etherlink,
    name: "etherlink",
  },
  {
    src: ffineryMarkets2,
    name: "ffineryMarkets2",
  },
  {
    src: fil,
    name: "fil",
  },
  {
    src: fineryMarkets1,
    name: "fineryMarkets1",
  },
  {
    src: flareFlrLogo,
    name: "flareFlrLogo",
  },
  {
    src: frame21,
    name: "frame21",
  },
  {
    src: frame22,
    name: "frame22",
  },
  {
    src: frame23,
    name: "frame23",
  },
  {
    src: frame2,
    name: "frame2",
  },
  {
    src: fraxtal2,
    name: "fraxtal2",
  },
  {
    src: fuel2,
    name: "fuel2",
  },
  {
    src: fuse,
    name: "fuse",
  },
  {
    src: gelato,
    name: "gelato",
  },
  {
    src: giddy2,
    name: "giddy2",
  },
  {
    src: harmonyOneLogo,
    name: "harmonyOneLogo",
  },
  {
    src: immutableX,
    name: "immutableX",
  },
  {
    src: iotx,
    name: "iotx",
  },
  {
    src: ipor1,
    name: "ipor1",
  },
  {
    src: kakarot1,
    name: "kakarot1",
  },
  {
    src: kadenaKdaLogo,
    name: "kadenaKdaLogo",
  },
  {
    src: kava,
    name: "kava",
  },
  {
    src: kinetix,
    name: "kinetix",
  },
  {
    src: kroma,
    name: "kroma",
  },
  {
    src: kryptomon,
    name: "kryptomon",
  },
  {
    src: landx,
    name: "landx",
  },
  {
    src: lavaLabs,
    name: "lavaLabs",
  },
  {
    src: lavaLabs1,
    name: "lavaLabs1",
  },
  {
    src: lineaLogo,
    name: "lineaLogo",
  },
  {
    src: link,
    name: "link",
  },
  {
    src: lisk2,
    name: "lisk2",
  },
  {
    src: logoWhite,
    name: "logoWhite",
  },
  {
    src: mantle1,
    name: "mantle1",
  },
  {
    src: mkr,
    name: "mkr",
  },
  {
    src: mode,
    name: "mode",
  },
  {
    src: monolith2,
    name: "monolith2",
  },
  {
    src: moonbeam,
    name: "moonbeam",
  },
  {
    src: morphBanner,
    name: "morphBanner",
  },
  {
    src: neon,
    name: "neon",
  },
  {
    src: oasisNetworkRoseLogo,
    name: "oasisNetworkRoseLogo",
  },
  {
    src: openCampusEduLogo,
    name: "openCampusEduLogo",
  },
  {
    src: optimismEthereumOpLogo,
    name: "optimismEthereumOpLogo",
  },
  {
    src: patchWallet,
    name: "patchWallet",
  },
  {
    src: polkadotNewDotLogo,
    name: "polkadotNewDotLogo",
  },
  {
    src: projectLogoTenet,
    name: "projectLogoTenet",
  },
  {
    src: quickswapLogo,
    name: "quickswapLogo",
  },
  {
    src: redstone,
    name: "redstone",
  },
  {
    src: reya,
    name: "reya",
  },
  {
    src: rootstock,
    name: "rootstock",
  },
  {
    src: saakuru1,
    name: "saakuru1",
  },
  {
    src: saga,
    name: "saga",
  },
  {
    src: sei,
    name: "sei",
  },
  {
    src: solanaSolLogo,
    name: "solanaSolLogo",
  },
  {
    src: sophon,
    name: "sophon",
  },
  {
    src: soroban,
    name: "soroban",
  },
  {
    src: stacksStxLogo,
    name: "stacksStxLogo",
  },
  {
    src: stader,
    name: "stader",
  },
  {
    src: starknetTokenStrkLogo,
    name: "starknetTokenStrkLogo",
  },
  {
    src: stratos,
    name: "stratos",
  },
  {
    src: superchain,
    name: "superchain",
  },
  {
    src: superrare3,
    name: "superrare3",
  },
  {
    src: superseedWhite,
    name: "superseedWhite",
  },
  {
    src: taiko,
    name: "taiko",
  },
  {
    src: telos1,
    name: "telos1",
  },
  {
    src: tenet1,
    name: "tenet1",
  },
  {
    src: thunderCoreLogoMark,
    name: "thunderCoreLogoMark",
  },
  {
    src: truflation1,
    name: "truflation1",
  },
  {
    src: vana,
    name: "vana",
  },
  {
    src: vector1,
    name: "vector1",
  },
  {
    src: vector2,
    name: "vector2",
  },
  {
    src: vector3,
    name: "vector3",
  },
  {
    src: vector4,
    name: "vector4",
  },
  {
    src: vector5,
    name: "vector5",
  },
  {
    src: vector,
    name: "vector",
  },
  {
    src: velasVlxLogo,
    name: "velasVlxLogo",
  },
  {
    src: vineMoney,
    name: "vineMoney",
  },
  {
    src: wemixLogo,
    name: "wemixLogo",
  },
  {
    src: xrpXrpLogo,
    name: "xrpXrpLogo",
  },
  {
    src: ygro,
    name: "ygro",
  },
  {
    src: ynthtetix,
    name: "ynthtetix",
  },
  {
    src: zora,
    name: "zora",
  },
  {
    src: zeebu,
    name: "zeebu",
  },
  {
    src: zetachain,
    name: "zetachain",
  },
  {
    src: zilliqaZilLogo,
    name: "zilliqaZilLogo",
  },
  {
    src: zkscroll1,
    name: "zkscroll1",
  },
];
